.hero {
    min-height: 100vh;
    background: linear-gradient(90deg, #f5f5dc, #12372A 120%);
    /* background: #EDA1C4; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 9%;
}

.hero-left {
    line-height: 1.1;
}

.femi-logo {
    width: 100px;
}

.hero-left h2 {
    color: #12372A;
    font-size: 25px;
}

.hero-left p {
    color: #12372A;
    font-size: 60px;
    font-weight: 700;
}

.hero-hand-icon {
    display: flex;
    align-items: center;
    gap: 20px;
}

.hero-hand-icon img {
    width: 70px;
}

.hero-latest-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 250px;
    height: 60px;
    border-radius: 75px;
    margin-top: 20px;
    background: #f1c93b;
    color: #12372A;
    font-size: 16px;
    font-weight: 1000;
    cursor: pointer;
}

.hero-latest-btn img {
    width: 18px;
}

.hero-right img {
    width: 450px;
}

.carousel-item img {
    max-height: 100%;
    max-width: 100%;
}

.hero-right {
    max-width: 450px;
}

/* Additional CSS to prevent resizing of side paragraph text */

@media (max-width: 1200px) {
    .hero {
        padding: 10px 6%;
    }

    .hero-left p {
        font-size: 70px;
    }

    .hero-right img {
        width: 400px;
    }
}

@media (max-width: 900px) {
    .hero-left p {
        font-size: 45px;
    }

    .hero-right img {
        width: 350px;
    }
}

@media (max-width: 700px) {
    .hero {
        padding: 40% 10% 0% 10%;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; /* Center content horizontally */
    }

    .hero-left p {
        font-size: 45px; /* Adjust font size for better readability */
    }

    .hero-right {
        max-width: 450px; /* Set max-width to ensure the carousel fills the container */
    }

    .carousel-item img {
        max-width: 100%; /* Ensure images in carousel fill their containers */
        height: auto; /* Preserve aspect ratio */
    }

    .hero-left {
        line-height: 1.2;
    }

    .hero-left h2 {
        margin-bottom: 20px;
    }

    .hero-latest-btn {
        margin-top: 30px;
    }
}


