.App {
  text-align: center;
}

*{
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.gif{
  width: 100%;
}

html{
  margin: 0;
}

body {
  margin: 0;
}

select {
  appearance: auto; /* Reset the appearance */
}

.App {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.book_container {
	width: 250px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
}

.book_img {
	width: 250px;
	height: 300px;
	object-fit: cover;
	border-radius: 10px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.book_name {
	font-size: 20px;
	font-weight: bold;
	margin: 5px 0;
	text-align: center;
}

.book_author {
	font-size: 16px;
	font-weight: 500;
	margin: 5px 0;
	text-align: center;
}

.book_price {
	font-size: 16px;
	font-weight: 500;
	margin: 5px 0;
	text-align: center;
}

.book_price > span {
	color: #21a700;
}

.buy_btn {
	width: 100% !important;
	height: 50px !important;
	outline: none !important;
	border: none !important;
	font-size: 12px !important;
	text-transform: uppercase !important; 
	font-weight: bold !important;
	cursor: pointer !important;
	border-bottom-right-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
	background-color: #f4d072 !important;
	margin-top: 10px !important;
}

.razorpay-payment{
	margin: 0 auto;
	display: flex;
	justify-content: center;
	
}



@media screen and (max-width: 700px) {
  .user-details {
      margin: 10% 5% 0 5% !important
  }
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
