.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px -2px black;
    padding: 15px 70px;
    position: fixed;
    width: 100%;
    background:  linear-gradient(90deg,#f5f5dc, #12372A 120%);
    z-index: 99;
}
*{
    margin: 0;
}
.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}

.hover-menu:hover {
    color: #FFD700; /* Change color on hover to a different color, for example, gold */
  }

.nav-logo p{
    color: #171717;
    font-size: 28px;
    font-weight: bolder;
}
.nav-menu{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 40px;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
}

.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}
.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 30px;
}

.nav-login-cart button{
    width: 130px;
    height: 45px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    background:linear-gradient(90deg, rgba(28, 173, 6, 0.715), rgba(2, 58, 15, 0.83));
    cursor: pointer;
}
.nav-login-cart button:active{
    background: #f3f3f3;
}
.nav-login-cart img{
    width: 35px;
}
.nav-cart-count{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    margin-left: -40px;
    font-size: 14px;
    background: red;
    color: white;
}
.nav-dropdown{
    display: none;
}

#get-name{
    color: #12372A;
    font-weight: 550;
    font-size: 1rem;
    background-color: #f5f5dc;
    padding: 5px 7px;
    border-radius: 5px;
}

.profile-dropdown{
    background-color: linear-gradient(90deg,#f5f5dc, #12372A 10%);
    color: #12372A;
}



@media (max-width: 750px) {
    .navbar {
      padding: 15px 20px;
    }
    .nav-login-cart {
      gap: 15px;
    }
    .nav-dropdown {
      display: block;
      width: 30px;
      transition: 0.5s;
      cursor: pointer;
    }
    .nav-menu {
      display: none;
      height: 80px;
      width: 100%;
      position: absolute;
      background-color: white;
      justify-content: center;
      top: 60px;
      left: 0;
    }
    .nav-menu-visible {
      display: flex;
    }
    .nav-dropdown.open {
      transform: rotate(90deg);
    }
    .nav-cart-count {
      margin-left: -25px;
    }
  }
  
  @media (max-width: 500px) {
    .navbar {
      padding: 15px 15px;
    }
  
    .nav-logo img {
      width: 30px;
    }
    .nav-logo p {
      font-size: 18px;
    }
    .nav-menu-visible {
      height: 70px;
      top: 60px;
    }
    .nav-login-cart {
      transform: scale(0.8);
      gap: 10px;
    }
  }
  