.descriptionbox{
    margin: 120px 170px;
    background: linear-gradient(90deg, rgba(7, 31, 1, 0.919), rgba(3, 77, 21, 0.651));
    color: #fff;
}
.descriptionbox-navigator{
    display: flex;
}
.descriptionbox-nav-box{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid #d0d0d0;
}
.descriptionbox-nav-box.fade{
    background: #FBFBFB;
    color: #fff;
}
.descriptionbox-description{
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #D0D0D0;
    padding: 48px;
    padding-bottom: 70px;
}
@media(max-width:1280px){
    .descriptionbox{
        margin: 60px 60px;
    }
}
@media(max-width:1280px){
    .descriptionbox{
        margin: 60px 20px;
    }
    .descriptionbox-description{
        font-size: 14px;
    }
}